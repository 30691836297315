import axios from 'axios'

export default {
    async get() {
        try {
            const response = await axios.get('/installments');
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async email(id,formData) {
        try {
            const response = await axios.post(`/installment/email/${id}`,formData);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async charge(id,formData) {
        try {
            const response = await axios.post(`/installment/charge/${id}`,formData);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async bouncedFunds(id) {
        try {
            const response = await axios.post(`/installment/bounced/${id}`);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async payment(id,formData) {
        try {
            const response = await axios.post(`/installment/payment/${id}`,formData);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
}
