<template>
  <div class="bg-white shadow-sm rounded-lg p-4">
    <div class="row mb-2 justify-content-between">
      <div v-if="discriminator === 'administrator'" class="col-4 ml-0 pr-2">
        <multiselect
          class="multiselect-top"
          v-model="officeFilter"
          @input="filterData"
          :options="offices"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Select Offices"
          label="office_name"
          track-by="id"
          :select-label="''"
          :deselect-label="''"
          @open="openBackgroundOpacity"
          @close="closeBackgroundOpacity"
        >
          <template slot="selection" slot-scope="{ values, search, isOpen }"
            ><span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
              >{{
                values
                  .map((item) => {
                    return item.office_name;
                  })
                  .join(", ")
              }}</span
            ></template
          >
        </multiselect>
      </div>
      <div class="col-4 ml-0 pr-2">
        <multiselect
          class="multiselect-top"
          v-model="clientFilter"
          @input="filterData"
          :options="clients"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Select Client"
          label="name"
          track-by="uuid"
          :select-label="''"
          :deselect-label="''"
          @open="openBackgroundOpacity"
          @close="closeBackgroundOpacity"
        >
          <template slot="selection" slot-scope="{ values, search, isOpen }"
            ><span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
              >{{
                values
                  .map((item) => {
                    return item.name;
                  })
                  .join(", ")
              }}</span
            ></template
          >
        </multiselect>
      </div>
      <div class="col-4 ml-0 pr-2">
        <multiselect
          class="multiselect-top"
          v-model="clientTypeFilter"
          @input="filterData"
          :options="clientTypes"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Select Client Type"
          label="name"
          track-by="name"
          :select-label="''"
          :deselect-label="''"
          @open="openBackgroundOpacity"
          @close="closeBackgroundOpacity"
        >
          <template slot="selection" slot-scope="{ values, search, isOpen }"
            ><span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
              >{{
                values
                  .map((item) => {
                    return item.name;
                  })
                  .join(", ")
              }}</span
            ></template
          >
        </multiselect>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-3 ml-0 pr-2">
        <multiselect
          class="multiselect-bottom"
          v-model="statusFilter"
          @input="filterData"
          :options="status"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Select Status"
          label="status"
          track-by="status"
          :select-label="''"
          :deselect-label="''"
          @open="openBackgroundOpacity"
          @close="closeBackgroundOpacity"
        >
          <template slot="selection" slot-scope="{ values, search, isOpen }"
            ><span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
              >{{
                values
                  .map((item) => {
                    return item.status;
                  })
                  .join(", ")
              }}</span
            ></template
          >
        </multiselect>
      </div>
      <div class="col-3 ml-0 pr-2">
        <multiselect
          class="multiselect-bottom"
          v-model="coverageFilter"
          @input="filterData"
          :options="coverages"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Select Coverage"
          label="name"
          track-by="id"
          :select-label="''"
          :deselect-label="''"
          @open="openBackgroundOpacity"
          @close="closeBackgroundOpacity"
        >
          <template slot="selection" slot-scope="{ values, search, isOpen }"
            ><span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
              >{{
                values
                  .map((item) => {
                    return item.name;
                  })
                  .join(", ")
              }}</span
            ></template
          >
        </multiselect>
      </div>
      <div class="col-3 ml-0 pr-2">
        <multiselect
          class="multiselect-bottom"
          v-model="carrierFilter"
          @input="filterData"
          :options="carriers"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Select Carrier"
          label="name"
          track-by="id"
          :select-label="''"
          :deselect-label="''"
          @open="openBackgroundOpacity"
          @close="closeBackgroundOpacity"
        >
          <template slot="selection" slot-scope="{ values, search, isOpen }"
            ><span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
              >{{
                values
                  .map((item) => {
                    return item.name;
                  })
                  .join(", ")
              }}</span
            ></template
          >
        </multiselect>
      </div>
      <div class="col-3 ml-0 pr-2">
        <date-picker type="date" v-model="dateFilter" @change="filterData" range format="MM/DD/YYYY" placeholder="Select Range Date"></date-picker>
      </div>
    </div>
    <el-table :data="tableData" class="w-100">
      <el-table-column
        label="Client Name"
        prop="policy.corporate.name"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column label="Type" prop="policy.insurance_type" :show-overflow-tooltip="true">
        <template slot-scope="scope">
        <span class="text-capitalize">
          {{
            scope.row.policy.insurance_type
          }}
        </span>
        </template>
      </el-table-column>
      <el-table-column label="Coverage" prop="policy.coverage.name" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="Carrier" prop="policy.carrier.name" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="Policy #" prop="policy.policy" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="Installment #" prop="number" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.number }}/{{scope.row.policy.installments}}
        </template>
      </el-table-column>
      <el-table-column label="Carrier Amount" prop="amount" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          $
          {{
            $formater.numberToFixed(scope.row.amount)
          }}
        </template>
      </el-table-column>
      <el-table-column label="CIA Fee" prop="c_fees" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          $
          {{
            $formater.numberToFixed(scope.row.c_fees)
          }}
        </template>
      </el-table-column>
      <el-table-column label="Amount Collected" prop="c_collection" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          $
          {{
            $formater.numberToFixed(scope.row.c_collection)
          }}
        </template>
      </el-table-column>
      <el-table-column label="Send Email" prop="email_date" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{
          scope.row.email_date | moment("MM-DD-YYYY")
        }}</template>
      </el-table-column>
      <el-table-column label="Charge Date" prop="collection_date">
        <template slot-scope="scope">{{
          scope.row.collection_date | moment("MM-DD-YYYY")
        }}</template>
      </el-table-column>
      <el-table-column label="Payment Date" prop="payment_date" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{
          scope.row.payment_date | moment("MM-DD-YYYY")
        }}</template>
      </el-table-column>
      <el-table-column label="Due Date" prop="due_date" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{
          scope.row.due_date | moment("MM-DD-YYYY")
        }}</template>
      </el-table-column>
      <el-table-column label="Status Bar" prop="status" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <div class="d-flex flex-row justify-content-center">
            <div class="p-2 status border border-dark" v-bind:class="{ 'bg-success': scope.row.status >= 1 }"></div>
            <div class="p-2 status border border-dark" v-bind:class="{ 'bg-success': scope.row.status >= 2 }"></div>
            <div class="p-2 status border border-dark" v-bind:class="{ 'bg-success': scope.row.status >= 3 }"></div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="" width="100">
        <template slot-scope="scope">
          <center>
            <el-button-group>
              <el-button
                size="mini"
                @click="processInstallment(scope.row, scope.$index)"
              >Process</el-button>
            </el-button-group>
          </center>
        </template>
      </el-table-column>
    </el-table>
    <modal name="process-modal" height="auto" :scrollable="true">
      <div class="row" style="padding: 15px">
        <div class="col-12 mb-5 el-drawer__header">
          <span role="heading">Insurance Installments</span>
        </div>
        <div v-if="processInstallmentData" class="col-12">
          <table class="table-sm table m-0 pt-1">
            <tbody>
              <tr>
                <td scope="row">Office:</td>
                <td>
                  {{ processInstallmentData.policy.corporate.office.office_name }}
                </td>
              </tr>
              <tr>
                <td scope="row">Insurance Type:</td>
                <td>
                  {{ processInstallmentData.policy.insurance_type==='c'?'Commercial':'Personal' }}
                </td>
              </tr>
              <tr>
                <td scope="row">Client:</td>
                <td>
                  {{ processInstallmentData.policy.corporate.name }}
                </td>
              </tr>
              <tr>
                <td scope="row">Coverage:</td>
                <td>
                  {{ processInstallmentData.policy.coverage.name }}
                </td>
              </tr>
              <tr>
                <td scope="row">Policy #:</td>
                <td>
                  {{ processInstallmentData.policy.policy }}
                </td>
              </tr>
              <tr>
                <td scope="row">Installment #:</td>
                <td>
                  {{ processInstallmentData.number }}/{{ processInstallmentData.policy.installments }}
                </td>
              </tr>
              <tr>
                <td scope="row">Zip Code:</td>
                <td>
                  {{ processInstallmentData.policy.corporate.zip }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 mt-3" v-if="processInstallmentData">
          <table class="w-100 table table-bordered">
            <thead>
              <tr>
                <th scope="col" class="text-center align-middle">Carrier</th>
                <th scope="col" class="text-center align-middle">Link</th>
                <th scope="col" class="text-center align-middle">User</th>
                <th scope="col" class="text-center align-middle">Password</th>
                <th scope="col" class="text-center align-middle">Notes</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center align-middle"> {{ processInstallmentData.policy.carrier.name }}</td>
                <td class="text-center align-middle"> <a :href="processInstallmentData.policy.link">{{ processInstallmentData.policy.link }}</a></td>
                <td class="text-center align-middle"> {{ processInstallmentData.policy.user }}</td>
                <td class="text-center align-middle"> {{ processInstallmentData.policy.password }}</td>
                <td class="text-center align-middle"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 items-center d-flex justify-content-center">
          <el-button class="mt-4" type="primary" @click="installmentMenu"
            >Next</el-button
          >
        </div>
      </div>
    </modal>
    <modal name="menu-modal" height="auto" width="800" :scrollable="true">
      <div class="row" style="padding: 15px">
        <div class="col-12 el-drawer__header">
          <span role="heading">Insurance Installments</span>
        </div>
        <div v-if="processInstallmentData" class="col-6">
            <p>
              Installment # {{ processInstallmentData.number }}/{{ processInstallmentData.policy.installments }}
            </p>
            <table class="table-sm table m-0 pt-1 table-borderless">
              <tbody>
                <tr>
                  <td class="col align-middle">
                    Installment Amount
                  </td>
                  <td v-if="!updateDataMenu" class="text-right" style="min-width:100px;">$ {{$formater.numberToFixed(processInstallmentData.amount)}}</td>
                  <td v-else><el-input style="width:100px;" type="number" v-model="processInstallmentData.amount"></el-input></td> 
                </tr>
                <tr>
                  <td class="col align-middle">
                    Other Fees
                  </td>
                  <td v-if="!updateDataMenu" class="text-right" style="min-width:100px;">$ {{$formater.numberToFixed(processInstallmentData.other_fees)}}</td>
                  <td v-else><el-input style="width:100px;" type="number" v-model="processInstallmentData.other_fees"></el-input></td> 
                </tr>
                <tr>
                  <td class="col align-middle">
                    Audit
                  </td>
                  <td v-if="!updateDataMenu" style="min-width:100px;">
                    <div class="text-right border-bottom border-dark" >
                    $ {{$formater.numberToFixed(processInstallmentData.audit)}}
                    </div>
                  </td>
                  <td v-else><el-input style="width:100px;" type="number" v-model="processInstallmentData.audit"></el-input></td> 
                </tr>
                <tr>
                  <td class="col align-middle">
                    Carrier Amount
                  </td>
                  <td v-if="!updateDataMenu" class="text-right" style="min-width:100px;">$ {{$formater.numberToFixed(processInstallmentData.carrier_amount)}}</td>
                  <td v-else><el-input style="width:100px;" type="number" v-model="processInstallmentData.carrier_amount"></el-input></td> 
                </tr>
                <tr>
                  <td class="col align-middle">
                    CIA Fees
                  </td>
                  <td v-if="!updateDataMenu" style="min-width:100px;">
                    <div class="text-right border-bottom border-dark" >
                    $ {{$formater.numberToFixed(processInstallmentData.c_fees)}}
                    </div>
                  </td>
                  <td v-else><el-input style="width:100px;" type="number" v-model="processInstallmentData.c_fees"></el-input></td> 
                </tr>
                <tr>
                  <td class="col align-middle">
                    Collection Amount
                  </td>
                  <td v-if="!updateDataMenu" class="text-right" style="min-width:100px;">$ {{$formater.numberToFixed(processInstallmentData.c_collection)}}</td>
                  <td v-else><el-input style="width:100px;" type="number" v-model="processInstallmentData.c_collection"></el-input></td> 
                </tr>
              </tbody>
            </table>
        </div>
        <div class="col-6" v-if="processInstallmentData">
          <table class="table-sm table m-0 pt-1 table-borderless">
            <thead>
              <tr>
                <td>Process</td>
                <td>Date</td>
                <td>By</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="width:110px">
                  <el-button style="width:90px" size="small" type="secondary" :disabled="processInstallmentData.email_date_processed !== null || processInstallmentData.status != 0" @click="emailModal"
                    >Send Email</el-button
                  >
                </td>
                <td class="align-middle">
                  {{ processInstallmentData.email_date_processed | moment("MM-DD-YYYY") }}
                </td>
                <td class="align-middle">
                  {{ processInstallmentData.email_responsible?processInstallmentData.email_responsible.name:'' }}
                </td>
              </tr>
              <tr v-if="processInstallmentData.status == 1">
                <td style="width:110px">
                  <el-button style="width:90px" size="small" type="secondary" :disabled="processInstallmentData.status != 1" @click="emailModal"
                    >Resend</el-button
                  >
                </td>
                <td class="align-middle">
                  {{ processInstallmentData.email_resend_date_processed | moment("MM-DD-YYYY") }}
                </td>
                <td class="align-middle">
                  {{ processInstallmentData.email_resend_responsible?processInstallmentData.email_resend_responsible.name:'' }}
                </td>
              </tr>
              <tr>
                <td style="width:110px">
                  <el-button style="width:90px" size="small" type="secondary" :disabled="processInstallmentData.collection_date_processed !== null || processInstallmentData.status != 1" @click="chargeModal"
                    >Charge</el-button
                  >
                </td>
                <td class="align-middle">
                  {{ processInstallmentData.collection_date_processed | moment("MM-DD-YYYY") }}
                </td>
                <td class="align-middle">
                  {{ processInstallmentData.collection_responsible?processInstallmentData.collection_responsible.name:'' }}
                </td>
              </tr>
              <tr>
                <td style="width:110px">
                  <el-button style="width:90px" size="small" type="secondary" :disabled="processInstallmentData.payment_date_processed !== null || processInstallmentData.status != 2" @click="verifyModal"
                    >Payment</el-button
                  >
                </td>
                <td class="align-middle">
                  {{ processInstallmentData.payment_date_processed | moment("MM-DD-YYYY") }}
                </td>
                <td class="align-middle">
                  {{ processInstallmentData.payment_responsible?processInstallmentData.payment_responsible.name:'' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 ml-1 mt-4" v-if="processInstallmentData">
          <p>
            Invoice # {{ processInstallmentData.invoce }}
          </p>
        </div>
      </div>
    </modal>

    <modal name="email-modal" height="auto" width="1000px" :scrollable="true">
      <div class="row" style="padding: 15px">
        <div class="col-12 mb-2 el-drawer__header">
          <span role="heading">Email Confirmation</span>
        </div>
        <div v-if="processInstallmentData" class="col-6">
          <el-form
            :model="formEmail"
            ref="formEmail"
            :hide-required-asterisk="true"
          >
            <div class="row" style="padding: 15px">
              <div class="col-12">
                <el-form-item
                  :label="$t('To:')"
                  prop="to"
                  :rules="[
                    { required: true, message: 'The field is required' },
                  ]"
                >
                  <el-input
                    type="text"
                    v-model="formEmail.to"
                    placeholder="To:"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="col-12">
                <el-form-item :label="$t('Cc:')" prop="cc">
                  <el-input
                    type="text"
                    v-model="formEmail.cc"
                    placeholder="Cc:"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="col-12">
                <el-form-item :label="$t('Bcc:')" prop="bcc">
                  <el-input
                    type="text"
                    v-model="formEmail.bcc"
                    placeholder="Bcc:"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="col-12">
                <el-form-item :label="$t('subject:')" prop="subject">
                  <el-input
                    type="text"
                    v-model="formEmail.subject"
                    placeholder="Subject:"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
        <div class="col-6" v-if="processInstallmentData">
          <div class="mt-2">
            <p>Dear {{ processInstallmentData.policy.corporate.name }}</p>
            <p>
              Below are the details for the following Insurance Payment:
            </p>
            <table class="table-sm mb-2">
              <tbody>
                <tr>
                  <td class="pl-0" style="width: 50%">Coverage:</td>
                  <td>
                    {{ processInstallmentData.policy.coverage.name }}
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">Payee:</td>
                  <td>
                    {{ processInstallmentData.policy.carrier.name }}
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">Bank Name:</td>
                  <td v-if="processInstallmentData.policy.corporate.credential_banks.length>0">
                    {{ processInstallmentData.policy.corporate.credential_banks[0].bank.name }}
                  </td>
                  <td v-else></td>
                </tr>
                <tr>
                  <td class="pl-0">Bank Account:</td>
                  <td v-if="processInstallmentData.policy.corporate.credential_banks.length>0">
                    {{ bankAccountX(processInstallmentData.policy.corporate.credential_banks[0].account) }}
                  </td>
                  <td v-else></td>
                </tr>
                <tr>
                  <td class="pl-0">Payment Date:</td>
                  <td>
                    <strong>
                      {{ processInstallmentData.payment_date | moment("MM/DD/YYYY") }}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">Payment Amount:</td>
                  <td>
                    <strong>$ {{$formater.numberToFixed(processInstallmentData.c_collection)}}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              The payment amount will be deducted from your bank account on the
              payment date.
            </p>
            <p>Sincerely,</p>
            <p>Insurance Team</p>
          </div>
        </div>

        <div class="col-12 items-center d-flex justify-content-center">
          <el-button class="mt-4" type="primary" @click="sendEmail"
            >Send</el-button
          >
        </div>
      </div>
    </modal>

    <modal name="charge-modal" height="auto" :scrollable="true">
      <div class="row" style="padding: 15px">
        <div class="col-12 mb-2 el-drawer__header">
          <span role="heading">Charge</span>
        </div>
        <div v-if="processInstallmentData" class="col-12">
          <el-form
            :model="formCharge"
            ref="formCharge"
            :hide-required-asterisk="true"
          >
            <div class="row" style="padding: 15px">
              <div class="col-12">
                <el-form-item
                  :label="$t('Charge Date')"
                  prop="chargeDate"
                  :rules="[{ required: true, message: 'The field is required' }]"
                >
                  <el-date-picker
                    class="w-100"
                    v-model="formCharge.chargeDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    format="MM/dd/yyyy"
                    placeholder="Pick a day"
                  ></el-date-picker>
                </el-form-item>
              </div>
              <div class="col-12">
                <el-form-item
                  :label="'Invoice #'"
                  prop="invoce"
                  :rules="[{ required: true, message: 'The field is required' }]"
                >
                  <el-input type="number" v-model="formCharge.invoce"></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
        <div class="col-12 items-center d-flex justify-content-center">
          <el-button class="mt-4" type="primary" @click="sendCharge"
            >Submit</el-button
          >
        </div>
      </div>
    </modal>

    <modal name="verify-modal" height="auto" :scrollable="true">
      <div class="row" style="padding: 15px">
        <div class="col-12 mb-2 el-drawer__header">
          <span role="heading">Before proceeding with payment, please verify funds are available</span>
        </div>
        <div class="col-12 items-center d-flex justify-content-center">
          <el-button class="mt-4" type="primary" @click="verifyFunds"
            >Verify</el-button
          >
          <el-button class="mt-4" type="primary" @click="bouncedFunds"
            >Bounced</el-button
          >
        </div>
      </div>
    </modal>

    <modal name="record-payment-modal" height="auto" :scrollable="true">
      <div class="row" style="padding: 15px">
        <div class="col-12 mb-2 el-drawer__header">
          <span role="heading">Payment</span>
        </div>
        <div v-if="processInstallmentData" class="col-12">
          <el-form
            :model="formPayment"
            ref="formPayment"
            :hide-required-asterisk="true"
          >
            <div class="row" style="padding: 15px">
            <div>
              <table class="table-sm mb-4 ml-3">
              <tbody>
                <tr>
                  <td class="pl-0">Carrier:</td>
                  <td>
                    {{ processInstallmentData.policy.carrier.name }}
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">Payment Amount:</td>
                  <td>
                    $ {{$formater.numberToFixed(processInstallmentData.carrier_amount)}}
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
              <div class="col-12">
                <el-form-item
                  :label="$t('Payment Date')"
                  prop="paymentDate"
                  :rules="[{ required: true, message: 'The field is required' }]"
                >
                  <el-date-picker
                    class="w-100"
                    v-model="formPayment.paymentDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    format="MM/dd/yyyy"
                    placeholder="Pick a day"
                  ></el-date-picker>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
        <div class="col-12 items-center d-flex justify-content-center">
          <el-button class="mt-4" type="primary" @click="sendPayment"
            >Record Payment</el-button
          >
        </div>
      </div>
    </modal>

    <router-link to="/admin/insurance">
      <el-button class="fixed-bottom new-register" type="primary"
        >Insurance Policies</el-button
      >
    </router-link>
  </div>
</template>

<script>
import installment from "@/services/api/installment";
import corporate from "@/services/api/corporate";
import office from "@/services/api/office";
import officeUser from "@/services/api/officeUser";
import Multiselect from "vue-multiselect";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import coverage from "@/services/api/coverage";
import carrier from "@/services/api/carrier";

export default {
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      data: [],
      index: null,
      tableData: [],
      officeFilter: [],
      clientFilter: [],
      clientTypeFilter: [],
      statusFilter: [],
      coverageFilter: [],
      carrierFilter: [],
      dateFilter:[null,null],
      discriminator: "",
      updateDataMenu: false,
      search: "",
      offices: [],
      clients: [],
      clientTypes: [{ name: "P" }, { name: "C" }],
      status: [{ value: 0, status: "To Mail" }, { value: 1, status: "To Charge" }, { value: 2, status: "To Pay"}, { value: 3, status: "Complete" }],
      coverages: [],
      carriers: [],
      processInstallmentData: null,
      formEmail: {
        to: null,
        cc: null,
        bcc: "installments@cabrerainsuranceagency.com",
        subject: null,
      },
      formCharge: {
        chargeDate: null,
        invoce: null,
      },
      formPayment: {
        paymentDate: null,
      },
    }
  },
  mounted() {
    coverage.get().then((response) => {
      this.coverages = response;
    });
    carrier.get().then((response) => {
      this.carriers = response;
    });
    switch (this.$store.getters.discriminator) {
      case "administrator":
        this.discriminator = "administrator";
        office.get().then((response) => {
          this.offices = response;
        });
        break;
      case "office":
      case "employee": {
        this.discriminator = "office";
        let idOffice = null;
        officeUser.getOffice(this.$store.getters.id).then((response) => {
          this.offices = response.map((item) => {
            idOffice = item.office.id;
            return {
              id: item.office.id,
              office_name: item.office.office_name,
            };
          });
        });
        break;
      }
    }
    installment.get().then((response) => {
      this.data = Object.assign([], response);
      this.tableData = response;
      console.log(response);
    });  
  },
  methods: {
    processInstallment(row, index) {
      this.index = index;
      if (row.policy.corporate != null) {
        this.processInstallmentData = Object.assign({}, row);
        this.show_modal_process();
      }
    },
    installmentMenu() {
      this.hide_modal_process();
      this.show_modal_menu();
    },
    bankAccountX(bankAccount){
      let bankAccountX =
          bankAccount.length > 4
            ? "x".repeat(bankAccount.length - 4) +
              bankAccount.substr(bankAccount.length - 4)
            : bankAccount;
      return bankAccountX
    },
    emailModal() {
      if(this.processInstallmentData) {
        this.formEmail.subject =
          this.processInstallmentData.policy.corporate.name +
          " - " +
          this.processInstallmentData.policy.coverage.name +
          "  Insurance Payment # " +
          this.processInstallmentData.number +
          "/"+this.processInstallmentData.policy.installments;
        if (this.processInstallmentData.policy.corporate !== null) {
          if (this.processInstallmentData.policy.corporate.shareholders.length > 0) {
            const shareholder = this.processInstallmentData.policy.corporate.shareholders[0];
            this.formEmail.to = shareholder.email;
          }
        }
        this.hide_modal_menu();
        this.show_modal_email();
      }
    },
    sendEmail() {

      const formData = {...this.processInstallmentData, ...this.formEmail, user_id: this.$store.getters.id}

      installment
        .email(this.processInstallmentData.id,formData)
        .then((response) => {
          this.formEmail = {
            to: null,
            cc: null,
            bcc: "installments@cabrerainsuranceagency.com",
            subject: null,
          };
          this.updateInstallment(response)
          this.hide_modal_email();
        })
        .catch(() => {
          this.$message({
            message: "Opps... Something wrong",
            type: "error",
            customClass: "message-error",
          });
        });
    },
    chargeModal() {
      if(this.processInstallmentData) {
        this.hide_modal_menu();
        this.show_modal_charge();
      }
    },
    sendCharge() {

      const formData = {...this.processInstallmentData, ...this.formCharge, user_id: this.$store.getters.id}

      installment
        .charge(this.processInstallmentData.id,formData)
        .then((response) => {
          this.formCharge = {
            chargeDate: null,
            invoce: null,
          };
          this.updateInstallment(response)
          this.hide_modal_charge();
        })
        .catch(() => {
          this.$message({
            message: "Opps... Something wrong",
            type: "error",
            customClass: "message-error",
          });
        });
    },
    verifyModal() {
      if(this.processInstallmentData) {
        this.hide_modal_menu();
        this.show_modal_verify();
      }
    },
    verifyFunds() {
      if(this.processInstallmentData) {
        this.hide_modal_verify();
        this.show_modal_record_payment();
      }
    },
    bouncedFunds() {
      installment
        .bouncedFunds(this.processInstallmentData.id)
        .then((response) => {
          this.updateInstallment(response)
          this.hide_modal_verify();
        })
        .catch(() => {
          this.$message({
            message: "Opps... Something wrong",
            type: "error",
            customClass: "message-error",
          });
        });
    },
    sendPayment() {

      const formData = {...this.processInstallmentData, ...this.formPayment, user_id: this.$store.getters.id}

      installment
        .payment(this.processInstallmentData.id,formData)
        .then((response) => {
          this.formPayment = {
            paymentDate: null,
          };
          this.updateInstallment(response)
          this.hide_modal_record_payment();
        })
        .catch(() => {
          this.$message({
            message: "Opps... Something wrong",
            type: "error",
            customClass: "message-error",
          });
        });
    },
    updateInstallment(installment) {
        this.tableData.splice(this.index, 1, installment);
        this.index = null;
        this.processInstallmentData = null
    },
    show_modal_process() {
      this.$modal.show("process-modal");
    },
    hide_modal_process() {
      this.$modal.hide("process-modal");
    },
    show_modal_menu() {
      this.updateDataMenu = false;
      this.$modal.show("menu-modal");
    },
    hide_modal_menu() {
      this.$modal.hide("menu-modal");
    },
    show_modal_email() {
      this.$modal.show("email-modal");
    },
    hide_modal_email() {
      this.$modal.hide("email-modal");
    },
    show_modal_charge() {
      this.$modal.show("charge-modal");
    },
    hide_modal_charge() {
      this.$modal.hide("charge-modal");
    },
    show_modal_verify() {
      this.$modal.show("verify-modal");
    },
    hide_modal_verify() {
      this.$modal.hide("verify-modal");
    },
    show_modal_record_payment() {
      this.$modal.show("record-payment-modal");
    },
    hide_modal_record_payment() {
      this.$modal.hide("record-payment-modal");
    },
    numberFormat(number) {
      const exp = /(\d)(?=(\d{3})+(?!\d))/g;
      const rep = '$1,';
      let arr = number.toString().split('.');
      arr[0] = arr[0].replace(exp,rep);
      return arr[1] ? arr.join('.'): arr[0];
    },
    getClients(idOffices) {
      this.clients = [];
      idOffices.forEach((id) => {
        corporate.getClientsByOffice(id).then((response) => {
          this.clients = this.clients.concat(response);
        });
      });
    },
    filterData() {
      let idOffices = this.officeFilter.map((office) => {
        return office.id;
      });

      let clients = this.clientFilter.map((client) => {
        return client.id;
      });

      let clientTypes = this.clientTypeFilter.map((user) => {
        return user.name.toLowerCase() ;
      });

      let status = this.statusFilter.map((status) => {
        return status.value;
      });

      let coverages = this.coverageFilter.map((coverage) => {
        return coverage.id;
      });

      let carriers = this.carrierFilter.map((carrier) => {
        return carrier.id;
      });

      this.tableData = Object.assign([], this.data);

      if (idOffices.length > 0) {
        this.tableData = this.tableData.filter(
          (item) =>
            idOffices.indexOf(item.policy.officeId) != -1
        );
      }

      if (idOffices.length !== this.lengthOfficeFilter) {
        this.lengthOfficeFilter = idOffices.length;
        this.getClients(idOffices);
      }
      if (idOffices.length == 0) {
        this.lengthOfficeFilter = 0;
        this.clientFilter = [];
        this.clients = [];
      }

      if (clients.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => clients.indexOf(item.policy.corporate.id) != -1
        );
      }

      if (clientTypes.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => clientTypes.indexOf(item.policy.insurance_type) != -1
        );
      }

      if (status.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => status.indexOf(item.status) != -1
        );
      }

      if (coverages.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => coverages.indexOf(item.policy.coverage.id) != -1
        );
      }

      if (carriers.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => carriers.indexOf(item.policy.carrier.id) != -1
        );
      }

      if(this.dateFilter[0]!=null && this.dateFilter[1]!=null){
        this.tableData=this.tableData.filter(item=>{
          var check = new Date(item.due_date);
          if(check > this.dateFilter[0] && check < this.dateFilter[1]){
            return true;
          }else{
            return false;
          }
        });
      }
    },
    handleClose(done) {
      this.$confirm("Are you sure you want to close this?")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
th,
td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.mx-datepicker{
    width:100% !important;
  }

  .mx-input{
    height: 42px !important;
  }

.status{
  width: 15px;
  height: 15px;
}
</style>
